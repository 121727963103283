/**
 * Checks if an ExtrasSection has any content to display
 * @param {Object} reservation - The reservation object
 * @param {Array} extraTypes - Array of extra types
 * @returns {Boolean} - Whether the section has content to display
 */
export const hasExtraSectionContent = (reservation, extraTypes) => {
  if (!reservation || !extraTypes) return false;

  // Check bookable services
  const bookableServices = reservation.bookableServices?.filter((service) => {
    const { type, activityStatus, code, hasAppliedBockedDays } = service;
    const isInExtraTypes = extraTypes.indexOf(type) > -1;
    const excludeStatuses = ["requested", "booked", "notBooked"];
    const hasExcludedStatus =
      activityStatus && excludeStatuses.includes(activityStatus.status);
    const excludeService =
      code === "LATE_CHE" ||
      code === "LATE_DEP" ||
      code === "EARLY_ARR" ||
      code === "AD_BT_C40";

    return (
      isInExtraTypes &&
      !hasExcludedStatus &&
      !excludeService &&
      !hasAppliedBockedDays
    );
  });

  // Check booked extras
  let bookedExtrasForGivenTypes = reservation?.bookedServices?.filter(
    (service) => {
      const { type, code } = service;
      return (
        extraTypes.indexOf(type) > -1 &&
        code !== "PETS" &&
        code !== "AD_DOG_CL7" &&
        code !== "AD_SN_200" &&
        code !== "MOBILITY" &&
        code !== "AD_SS_C56" &&
        code !== "KLIM"
      );
    }
  );

  // Include bookable services that have activityStatus set to "booked"
  const bookedBookableServices = reservation?.bookableServices?.filter(
    (service) => {
      const { type, activityStatus, code } = service;
      return (
        extraTypes.indexOf(type) > -1 &&
        activityStatus?.status === "booked" &&
        code !== "PETS" &&
        code !== "AD_DOG_CL7" &&
        code !== "AD_SN_200" &&
        code !== "MOBILITY" &&
        code !== "AD_SS_C56" &&
        code !== "KLIM"
      );
    }
  );

  bookedExtrasForGivenTypes = [
    ...(bookedExtrasForGivenTypes || []),
    ...(bookedBookableServices || []),
  ];

  // Check requested extras
  const requestedExtrasForGivenTypes = reservation?.bookableServices?.filter(
    (extra) => {
      return (
        extraTypes.indexOf(extra.type) > -1 &&
        extra.activityStatus &&
        extra.activityStatus?.status === "requested"
      );
    }
  );

  // Check denied extras
  const deniedExtrasForGivenTypes = reservation?.bookableServices?.filter(
    (extra) => {
      return (
        extraTypes.indexOf(extra.type) > -1 &&
        extra.activityStatus &&
        extra.activityStatus?.status === "notBooked"
      );
    }
  );

  // Return true if any of the arrays have items
  return (
    (bookableServices && bookableServices.length > 0) ||
    (bookedExtrasForGivenTypes && bookedExtrasForGivenTypes.length > 0) ||
    (requestedExtrasForGivenTypes && requestedExtrasForGivenTypes.length > 0) ||
    (deniedExtrasForGivenTypes && deniedExtrasForGivenTypes.length > 0)
  );
};
