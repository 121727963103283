import { ButtonToSmall } from "./styles/ButtonLink.styled";

function ButtonExtra({ className = "", to = "/", text, analyticsProductId }) {
  return (
    <ButtonToSmall
      className={className}
      to={to}
      data-ph-capture-attribute-extra-action="to-extra-listing"
      data-ph-capture-attribute-product-id={analyticsProductId}
    >
      {text}
    </ButtonToSmall>
  );
}

export default ButtonExtra;
