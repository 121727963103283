import logo from "assets/images/logo.svg";
import { LinkButtonTo } from "atoms/styles/ButtonLink.styled";
import { useAppContext } from "provider/AppProvider";
import { useReservationFromQueryParams } from "utils/helper";
import dict from "utils/translations";
import Logout from "../atoms/Logout";
import { Image } from "../components/Contents/styles/Contents.styled";
import {
  ButtonContainer,
  FormWrapper,
  LoginBox,
  LoginIntro,
  LoginText,
  LoginTitle,
  LoginWrapper,
  Logo,
} from "./styles/Login.styled";

function AfterStay() {
  const { lang, user } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();

  const feedbackURL = useFeedbackURL(user, reservation);

  if (!reservation) return null;
  return (
    <LoginWrapper className="animate">
      <Logo src={logo} alt="logo" />
      <Image src="/images/onlineshop.jpg" alt="picture" />
      <LoginBox>
        <LoginTitle>
          {dict("pages.afterTitle", lang)} {user.firstName},{" "}
        </LoginTitle>
        <LoginText>{dict("pages.afterIntro", lang)}</LoginText>
        <LoginText>{dict("pages.afterText", lang)}</LoginText>
        <LoginText>{dict("pages.afterTextShop", lang)}</LoginText>
        <LoginIntro>{dict("pages.afterOutro", lang)}</LoginIntro>
        <FormWrapper>
          <ButtonContainer>
            <LinkButtonTo className="green" type="button" href={feedbackURL}>
              {dict("pages.afterButton", lang)}
            </LinkButtonTo>
            <br />
            <LinkButtonTo
              className="blue"
              type="button"
              href="https://shop.raus.life/?utm_source=raus-companion&utm_medium=after-stay&utm_campaign=after-stay"
              target="_blank"
            >
              {dict("pages.shopCTA", lang)}
            </LinkButtonTo>
            <Logout />
          </ButtonContainer>
        </FormWrapper>
      </LoginBox>
    </LoginWrapper>
  );
}

const useFeedbackURL = (user, reservation) => {
  const baseURL = `https://form.jotform.com/233344115797359?email=${encodeURIComponent(
    user.email
  )}&reservationId=${encodeURIComponent(
    reservation.id
  )}&locationName=${encodeURIComponent(
    reservation.property.name
  )}&name=${encodeURIComponent(user.firstName)}`;

  const hostModelParam = reservation?.hubSpotDeal?.is_host20_booking
    ? "&hostModel=HOST_2.0"
    : "";

  return baseURL + hostModelParam;
};

export default AfterStay;
