import { useAppContext } from "provider/AppProvider";
import { useReservationFromQueryParams } from "utils/helper";
import { shouldShowHotTub, shouldShowSauna } from "../utils/serviceHelpers";
import dict from "../utils/translations";
import {
  AlignIcon,
  CardText,
  MarginP,
  NoMarginP,
  ThemedCard,
  ThemedCardInfo,
  ThemedCardTitle,
  TopMarginP,
} from "./Contents/styles/Contents.styled";

function FireWarning() {
  const { lang, user } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  const cabin = reservation?.cabin;
  const spotCode = reservation?.property?.code;

  if (!cabin) return null;

  let hasSauna = shouldShowSauna({
    bookedServices: reservation.bookedServices,
    spotCode: spotCode,
  });
  let hasHotTub = shouldShowHotTub({
    bookedServices: reservation.bookedServices,
    spotCode: spotCode,
  });

  return (
    <ThemedCard theme="black">
      <CardText>
        <ThemedCardInfo>
          <AlignIcon noPadding>
            <div>
              <ThemedCardTitle theme="black">
                {dict("contents.fireTitle", lang)}
              </ThemedCardTitle>
              <MarginP
                dangerouslySetInnerHTML={{
                  __html: `${dict("contents.fireText", lang)} ${
                    cabin.wbs_value
                  }.`,
                }}
              />
              <NoMarginP
                dangerouslySetInnerHTML={{
                  __html: `${dict("contents.fireSubtext", lang)}`,
                }}
              />
              {cabin.wbs_value >= 4 && (hasSauna || hasHotTub) ? (
                <TopMarginP
                  dangerouslySetInnerHTML={{
                    __html: `${dict("contents.fireSubtextSauna", lang)}`,
                  }}
                />
              ) : null}
            </div>
          </AlignIcon>
        </ThemedCardInfo>
      </CardText>
    </ThemedCard>
  );
}

export default FireWarning;
