import ButtonDiv from "atoms/ButtonDiv";
import BeforeTrip from "components/Contents/BeforeTrip";
import Checkout from "components/Contents/Checkout";
import ExtrasSection from "components/Contents/ExtrasSection";
import HelpSecurity from "components/Contents/HelpSecurity";
import {
  ContentBox,
  InfoBox,
} from "components/Contents/styles/Contents.styled";
import TravelInfo from "components/Contents/TravelInfo";
import IFrameWrapper from "components/IFrameWrapper";
import { HeaderSubpageWrapper, Logo } from "components/styles/Header.styled.js";
import Modal from "react-modal";
import down from "../assets/images/keyboard_arrow_down.svg";
import up from "../assets/images/keyboard_arrow_up.svg";
import logo from "../assets/images/logo.svg";
import {
  ContentWrapper,
  Icon,
  Item,
  Tile,
  TileWrapper,
  Title,
  TitleWrapper,
} from "../atoms/styles/Collapsible.styled.js";
import { hasExtraSectionContent } from "../utils/extraSectionHelpers";
import dict from "../utils/translations";

const CollapsibleContent = ({
  props,
  contentItem,
  expanded,
  setExpanded,
  reservation,
  cabin,
  property,
  daysDiff,
  lang,
  modalIsOpen,
  openModal,
  afterOpenModal,
  closeModal,
  modalStyle,
  modalCloseButton,
  shouldShowSauna,
  shouldShowHotTub,
}) => {
  // Check if this is an extras section and if it has content
  const isExtrasSection = [
    "dog",
    "kids",
    "food",
    "activities",
    "mobility",
    "additional",
  ].includes(props.id);

  const hasContent = isExtrasSection
    ? hasExtraSectionContent(reservation, props.extraTypes)
    : (contentItem.contents && Object.keys(contentItem.contents).length > 0) ||
      contentItem.showAlways;

  // Don't render anything if there's no content
  if (!hasContent) return null;

  return (
    <div id={props?.id}>
      <Tile>
        <Item>
          <TileWrapper>
            <Title onClick={() => setExpanded(!expanded)}>
              <TitleWrapper>
                <img src={props.icon} alt="icon" width={32} height={32} />
                {dict(`contentItems.${props.id}.title`, lang)}
              </TitleWrapper>
              <Icon>
                {expanded ? (
                  <img src={up} alt="up-icon" />
                ) : (
                  <img src={down} alt="down-icon" />
                )}
              </Icon>
            </Title>
          </TileWrapper>
          <ContentWrapper open={expanded}>
            {props.id === "beforeTrip" ? (
              <BeforeTrip reservation={reservation} daysDiff={daysDiff} />
            ) : props.id === "myStay" ? (
              !cabin ? (
                <>{dict("contents.noCabinAssigned", lang)}</>
              ) : (
                <>
                  <TravelInfo
                    contents={contentItem.contents}
                    showSauna={shouldShowSauna({
                      bookedServices: reservation.bookedServices,
                      spotCode: property.code,
                    })}
                    showHotTub={shouldShowHotTub({
                      bookedServices: reservation.bookedServices,
                      spotCode: property.code,
                    })}
                  />
                  {cabin.revault_location_id ? (
                    <ContentBox>
                      <InfoBox>
                        <ButtonDiv
                          onClick={openModal}
                          caption={dict(
                            "contentItems.myStay.reportIncident",
                            lang
                          )}
                        />
                        <Modal
                          isOpen={modalIsOpen}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={modalStyle}
                          contentLabel=""
                        >
                          <HeaderSubpageWrapper
                            style={{ padding: "10px 16px 0px" }}
                          >
                            <Logo src={logo} alt="logo" />
                            <button
                              onClick={closeModal}
                              style={modalCloseButton}
                            >
                              {dict("atoms.close", lang)}
                            </button>
                          </HeaderSubpageWrapper>
                          <IFrameWrapper
                            locationId={cabin.revault_location_id}
                            reservationId={reservation.id}
                          />
                        </Modal>
                      </InfoBox>
                    </ContentBox>
                  ) : null}
                </>
              )
            ) : isExtrasSection ? (
              <ExtrasSection
                contents={contentItem.contents}
                reservation={reservation}
                extraContentItem={props.extraContentItem}
                extraTypes={props.extraTypes}
              />
            ) : props.id === "checkout" ? (
              <Checkout reservation={reservation} />
            ) : props.id === "help" ? (
              <HelpSecurity
                contents={contentItem.contents}
                daysDiff={daysDiff}
              />
            ) : null}
          </ContentWrapper>
        </Item>
      </Tile>
    </div>
  );
};

export default CollapsibleContent;
