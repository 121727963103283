import iconPurple from "assets/images/error-purple.svg";
import errorIcon from "assets/images/error.svg";
import infoIcon from "assets/images/info.svg";
import { useAppContext } from "provider/AppProvider";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatPrice } from "utils/formatPrice";
import { priceType } from "utils/priceType";
import { serviceImage } from "utils/serviceImage";
import dict from "utils/translations";
import ButtonExtra from "../atoms/ButtonExtra";
import {
  AlignIcon,
  AlignIconTop,
  ButtonWrapper,
  Card,
  CardImage,
  CardInfo,
  CardPrice,
  CardText,
  CardTitle,
  SuccessInfoBox,
  SuccessInfoTitle,
  ThemedCard,
  ThemedCardBox,
  ThemedCardInfo,
  ThemedCardTitle,
} from "./Contents/styles/Contents.styled";
const ExtraCardInfo = styled(CardInfo)`
  color: ${({ isUnavailable }) => (isUnavailable ? "#b90707" : "inherit")};
`;

const ExtraCardPrice = styled(CardPrice)`
  filter: ${({ isUnavailable }) =>
    isUnavailable ? "grayscale(100%)" : "none"};
`;

const ExtraCardImage = styled(CardImage)`
  filter: ${({ isUnavailable }) =>
    isUnavailable ? "grayscale(100%)" : "none"};
  opacity: ${({ isUnavailable }) => (isUnavailable ? "0.5" : "1")};
`;

function ExtrasCard({
  disabled,
  showTotalPrice,
  bookedExtrasForGivenTypes,
  service,
  showSubTotal,
  showIcon,
  bookingInfo,
  buttonProps,
  ...props
}) {
  const { lang } = useAppContext();
  const navigate = useNavigate();

  const totalPrice = useMemo(() => {
    if (showTotalPrice && service.totalAmount) {
      return parseFloat(service.totalAmount.grossAmount);
    }
    return null;
  }, [showTotalPrice, service]);

  const calculateSubTotal = useMemo(() => {
    if (!service) return 0;

    let baseAmount = 0;
    if (
      service.dates.length > 0 &&
      service.dates[0].amount &&
      service.dates[0].amount.grossAmount
    ) {
      baseAmount = parseFloat(service.dates[0].amount.grossAmount);
    } else {
      baseAmount = parseFloat(service.totalAmount.grossAmount);
    }

    if (
      service.availabilityMode === "Daily" &&
      service.pricingUnit === "Person"
    ) {
      // Case 3
      return baseAmount / service.count;
    } else if (
      service.availabilityMode === "Arrival" &&
      service.pricingUnit === "Person"
    ) {
      // Case 4
      return baseAmount / service.count;
    } else {
      // Case 1 and other cases
      return baseAmount;
    }
  }, [service]);

  function renderStatusComponent(status, lang, bookedExtrasForGivenTypes) {
    // console.log("status", status);
    // console.log("bookedExtrasForGivenTypes", bookedExtrasForGivenTypes);
    switch (status) {
      case "requested":
        return (
          <ThemedCardBox noMargin>
            <ThemedCard noMargin theme="orange">
              <CardText>
                <ThemedCardInfo>
                  <AlignIcon noPadding>
                    <img src={iconPurple} alt="icon" />
                    <div>
                      <ThemedCardTitle noMargin theme="orange">
                        {dict("extraContentItems.activity.pendingTitle", lang)}
                      </ThemedCardTitle>
                    </div>
                  </AlignIcon>
                </ThemedCardInfo>
              </CardText>
            </ThemedCard>
          </ThemedCardBox>
        );
      case "booked":
        return (
          <SuccessInfoBox noMargin>
            <SuccessInfoTitle>
              {dict("extraContentItems.activity.activityConfirmed", lang)}{" "}
              {dict("extraContentItems.activity.activityConfirmedFor", lang)}{" "}
              {`${formatPrice(totalPrice)}€`}{" "}
            </SuccessInfoTitle>
          </SuccessInfoBox>
        );
      case "notBooked":
        return (
          <ThemedCardBox noMargin>
            <ThemedCard noMargin theme="red">
              <CardText>
                <ThemedCardInfo>
                  <AlignIcon noPadding>
                    <img src={errorIcon} alt="icon" />
                    <div>
                      <ThemedCardTitle noMargin theme="red">
                        {dict("extraContentItems.activity.deniedTitle", lang)}
                      </ThemedCardTitle>
                    </div>
                  </AlignIcon>
                </ThemedCardInfo>
              </CardText>
            </ThemedCard>
          </ThemedCardBox>
        );
      default:
        // We will check bookedExtrasForGivenTypes only if status doesn't match any of the above
        if (
          bookedExtrasForGivenTypes &&
          bookedExtrasForGivenTypes.some((s) => s.id === service.id)
        ) {
          return (
            <SuccessInfoBox noMargin>
              <SuccessInfoTitle>
                {dict("contents.extraBooked", lang)}{" "}
                {dict("extraContentItems.activity.activityConfirmedFor", lang)}{" "}
                {`${formatPrice(totalPrice)}€`}{" "}
              </SuccessInfoTitle>
            </SuccessInfoBox>
          );
        }
        return null;
    }
  }

  const cardContent = (
    <Card>
      {service.overviewImage?.image.data?.attributes ? (
        <ExtraCardImage isUnavailable={disabled}>
          <img
            src={serviceImage(
              service.overviewImage?.image.data?.attributes.formats
            )}
            alt="card-image"
          />
        </ExtraCardImage>
      ) : (
        ""
      )}
      <CardText>
        <CardTitle>{service.name}</CardTitle>
        {showSubTotal && (
          <ExtraCardPrice isUnavailable={disabled}>
            {calculateSubTotal === 0
              ? dict("contents.freeExtra", lang)
              : `${formatPrice(calculateSubTotal)}€ ${priceType(
                  service,
                  lang
                )}`}
          </ExtraCardPrice>
        )}
        {totalPrice !== null && <ExtraCardPrice></ExtraCardPrice>}
        <ExtraCardInfo isUnavailable={disabled}>
          {showIcon && bookingInfo && (
            <AlignIconTop>
              <img src={disabled ? errorIcon : infoIcon} alt="icon" />
              {bookingInfo}
            </AlignIconTop>
          )}
          {renderStatusComponent(
            service.activityStatus?.status,
            lang,
            bookedExtrasForGivenTypes
          )}
        </ExtraCardInfo>
        {!disabled && (
          <ButtonWrapper>
            <ButtonExtra {...buttonProps} />
          </ButtonWrapper>
        )}
      </CardText>
    </Card>
  );

  return disabled ? (
    <div style={{ pointerEvents: "none" }}>{cardContent}</div>
  ) : (
    <div onClick={() => navigate(buttonProps.to)}>{cardContent}</div>
  );
}

export default ExtrasCard;
