/**
 * Helper functions for service-related operations
 */

/**
 * Checks if a service is booked in the reservation
 * @param {Array} bookedServices - List of booked services
 * @param {string} serviceCode - Service code to check
 * @returns {boolean} True if service is booked
 */
export function isServiceBooked(bookedServices, serviceCode) {
  return (
    Array.isArray(bookedServices) &&
    bookedServices.some((service) =>
      [serviceCode, "SAUNA"].includes(service.code)
    )
  );
}

/**
 * Checks if a service is free in a specific location
 * @param {Object} params - Parameters
 * @param {string} params.serviceCode - Service code to check
 * @param {string} params.spotCode - Location code
 * @returns {boolean} True if service is free in the location
 */
export function isServiceFreeInLocation({ serviceCode, spotCode }) {
  const freeServicesByLocationsMap = {
    SPOT4: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
    SPOT13: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
    SPOT14: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
    SPOT29: ["AD_SAU_DS", "AD_HOT_DS", "AD_SAUNA"],
    SPOT49: ["AD_SAU_C27"],
  };
  if (!freeServicesByLocationsMap?.[spotCode]) return false;
  return freeServicesByLocationsMap[spotCode].includes(serviceCode);
}

/**
 * Checks if sauna should be shown for a reservation
 * @param {Object} params - Parameters
 * @param {Array} params.bookedServices - List of booked services
 * @param {string} params.spotCode - Location code
 * @returns {boolean} True if sauna should be shown
 */
export function shouldShowSauna({ bookedServices, spotCode }) {
  const saunaServiceCodes = ["AD_SAU_DS", "AD_SAU_C27"];
  return saunaServiceCodes.some(
    (serviceCode) =>
      isServiceFreeInLocation({ serviceCode, spotCode }) ||
      isServiceBooked(bookedServices, serviceCode)
  );
}

/**
 * Checks if hot tub should be shown for a reservation
 * @param {Object} params - Parameters
 * @param {Array} params.bookedServices - List of booked services
 * @param {string} params.spotCode - Location code
 * @returns {boolean} True if hot tub should be shown
 */
export function shouldShowHotTub({ bookedServices, spotCode }) {
  const hotTubServiceCodes = ["AD_HOT_DS", "AD_BT_C40"];
  return hotTubServiceCodes.some(
    (serviceCode) =>
      isServiceFreeInLocation({ serviceCode, spotCode }) ||
      isServiceBooked(bookedServices, serviceCode)
  );
}
