import { useEffect, useState } from "react";
import CollapsibleContent from "../components/CollapsibleContent";
// import Extras from '../components/Contents/Extras'
import { useAppContext } from "provider/AppProvider";
import Modal from "react-modal";
import { toast } from "react-toastify";
import getCompanionContent from "../utils/content";
import { shouldShowHotTub, shouldShowSauna } from "../utils/serviceHelpers";
import dict from "../utils/translations";

Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    zIndex: 2,
    background: "#fdf9ed",
    border: 0,
  },
  content: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: "0 0 0",
    zIndex: 3,
    borderRadius: 0,
    border: "none",
    background: "#fdf9ed",
  },
};

const modalCloseButton = {
  cursor: "pointer",
  borderRadius: "14px",
  border: "none",
  padding: "13px 12px",
  height: "48px",
  color: "#ffffff",
  background: "#23212c",
};

function CollapsibleReservation({
  reservation,
  isExpanded = false,
  contentItem,
  ...props
}) {
  // console.log("reservation", reservation);
  const cabin = reservation.cabin;
  const property = reservation.property;
  const locationContent = reservation.locationContent;
  const cabinContent = reservation.cabinContent;
  const unitGroupContent = reservation.unitGroupContent;

  // todo move to store
  const [expanded, setExpanded] = useState(
    localStorage.getItem(`expanded-${props.id}-${reservation.id}`) === "true" ||
      isExpanded
  );

  useEffect(() => {
    localStorage.setItem(`expanded-${props.id}-${reservation.id}`, expanded);
  }, [expanded]);

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    window.addEventListener(
      "message",
      (evt) => {
        console.log("message", evt);
        if (
          evt.origin === "https://app.revaultapp.co" &&
          (evt.data === "success" || evt.data === "error")
        ) {
          toast.success(dict("contentItems.myStay.reportedIncident", lang));
          closeModal();
        }
      },
      { once: true }
    );
  }

  function closeModal() {
    setIsOpen(false);
  }

  // data prep for beforetrip
  if (props.id === "beforeTrip") {
    let packliste = getCompanionContent(
      "packliste",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    let tips = getCompanionContent(
      "region_tips",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (props.id === "beforeTrip" && packliste) {
      contentItem.contents["packliste"] = packliste;
    }
    if (props.id === "beforeTrip" && tips) {
      contentItem.contents["tips"] = tips;
    }
  }

  if (props.id === "myStay") {
    // aka "your cabin"
    const rules = getCompanionContent(
      "cabin-rules",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (rules) {
      contentItem.contents["cabin-rules"] = rules;
    }

    const electricity = getCompanionContent(
      "electricity_water",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (electricity) {
      contentItem.contents["electricity_water"] = electricity;
    }

    const heating = getCompanionContent(
      "heating",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (heating) {
      contentItem.contents["heating"] = heating;
    }

    const stove = getCompanionContent(
      "stove",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (stove) {
      contentItem.contents["stove"] = stove;
    }

    const generator = getCompanionContent(
      "generator",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (generator) {
      contentItem.contents["generator"] = generator;
    }

    const sauna = getCompanionContent(
      "sauna",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (sauna) {
      contentItem.contents["sauna"] = sauna;
    }

    const hottub = getCompanionContent(
      "hottub",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (hottub) {
      contentItem.contents["hottub"] = hottub;
    }

    const doors = getCompanionContent(
      "doors-windows",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (doors) {
      contentItem.contents["doors-windows"] = doors;
    }

    const toilet = getCompanionContent(
      "toilet",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (toilet) {
      contentItem.contents["toilet"] = toilet;
    }

    const grill = getCompanionContent(
      "grill",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (grill) {
      contentItem.contents["grill"] = grill;
    }

    const overview = getCompanionContent(
      "overview_where_to_find_what",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (overview) {
      contentItem.contents["overview_where_to_find_what"] = overview;
    }
  }

  //data prep for security and emergency
  if (props.id === "help") {
    const emergency = getCompanionContent(
      "emergency",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (emergency) {
      contentItem.contents["emergency"] = emergency;
    }

    const safety = getCompanionContent(
      "safety",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (safety) {
      contentItem.contents["safety"] = safety;
    }

    const helpingHandGuide = getCompanionContent(
      "helping_hand_guide",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (helpingHandGuide) {
      contentItem.contents["helping_hand_guide"] = helpingHandGuide;
    }

    const urgentFAQs = getCompanionContent(
      "urgent_faqs",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (urgentFAQs) {
      contentItem.contents["urgent_faqs"] = urgentFAQs;
    }
  }

  //data prep for checkout
  if (props.id === "checkout") {
    const checkout = getCompanionContent(
      "checkout",
      locationContent,
      unitGroupContent,
      cabinContent
    );
    if (checkout) {
      contentItem.contents["checkout"] = checkout;
    }
  }

  //show only if cabinManual content exists
  if (props.id === "cabinManual") {
    let cabinManual = JSON.parse(localStorage.getItem("cabin_manual"));
    contentItem.contents = cabinManual;
  }

  const { lang } = useAppContext();

  const reservationArrivalDate = new Date(reservation.arrival);
  const currentDate = new Date();

  // Calculate the difference between the reservation arrival date and the current date in milliseconds
  const timeDiff = reservationArrivalDate.getTime() - currentDate.getTime();

  // Calculate the difference in days
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  return (
    <CollapsibleContent
      props={props}
      contentItem={contentItem}
      expanded={expanded}
      setExpanded={setExpanded}
      reservation={reservation}
      cabin={cabin}
      property={property}
      daysDiff={daysDiff}
      lang={lang}
      modalIsOpen={modalIsOpen}
      openModal={openModal}
      afterOpenModal={afterOpenModal}
      closeModal={closeModal}
      modalStyle={modalStyle}
      modalCloseButton={modalCloseButton}
      shouldShowSauna={shouldShowSauna}
      shouldShowHotTub={shouldShowHotTub}
    />
  );
}

export default CollapsibleReservation;
