export const contents = [
  {
    id: "beforeTrip",
    icon: "/images/explore.svg",
    contents: [],
  },
  {
    id: "myStay",
    icon: "/images/cabin.svg",
    contents: [],
    showAlways: true,
  },
  {
    id: "cabinRules",
    icon: "/images/checklist_rtl.svg",
    contents: [],
  },
  {
    id: "dog",
    icon: "/images/dog.svg",
    contents: [],
    extraTypes: ["Dog"],
    extraContentItem: "dog",
  },
  {
    id: "kids",
    icon: "/images/bunny.svg",
    contents: [],
    extraTypes: ["Kids"],
    extraContentItem: "kids",
  },
  {
    id: "food",
    icon: "/images/restaurant.svg",
    contents: [],
    extraTypes: ["F&B"],
    extraContentItem: "food",
  },
  {
    id: "activities",
    icon: "/images/local_activity.svg",
    contents: [],
    extraTypes: ["Experiences", "Experience-on-request"],
    extraContentItem: "activity",
  },
  {
    id: "mobility",
    icon: "/images/mobility.svg",
    contents: [],
    extraTypes: ["Mobility"],
    extraContentItem: "mobility",
  },
  {
    id: "additional",
    icon: "/images/sell.svg",
    contents: [],
    extraTypes: ["Additional"],
    extraContentItem: "additional",
  },
  {
    id: "help",
    icon: "/images/health_and_safety.svg",
    contents: [],
  },
  {
    id: "basicFacts",
    icon: "/images/explore.svg",
    contents: [],
  },
  {
    id: "checkout",
    icon: "/images/logout.svg",
    contents: [],
  },
];

export const extraContents = [
  {
    id: "F&B",
    icon: "/images/restaurant.svg",
    contents: [],
  },
  {
    id: "Experiences",
    icon: "/images/local_activity.svg",
    contents: [],
  },
];

export const ReservationsListData = [
  {
    id: "bookedCabins",
    title: "Gebuchte Cabins",
    expanded: true,
  },
];
