import { useAppContext } from "provider/AppProvider";
import dict from "../../utils/translations";
import ExtrasCard from "../ExtrasCard";
import BookableExtras from "./BookableExtras";
import {
  ContentBox,
  ExtrasCardWrapper,
  InfoBox,
} from "./styles/Contents.styled";

function ExtrasSection({ reservation, extraContentItem, extraTypes }) {
  const { lang } = useAppContext();
  const reservationId = reservation.id;

  // console.log("extraTypes", extraTypes);

  const hasAvailableBookableServicesForExtraTypes = () => {
    return availableExtrasWithoutStatus.some((extra) =>
      extraTypes.includes(extra.type)
    );
  };

  let bookedExtrasForGivenTypes = reservation?.bookedServices?.filter(
    (service) => {
      const { type, code } = service;
      return (
        extraTypes.indexOf(type) > -1 &&
        code !== "PETS" &&
        code !== "AD_SN_200" &&
        code !== "MOBILITY" &&
        code !== "AD_SS_C56" &&
        code !== "KLIM"
      );
    }
  );
  // console.log("bookedExtrasForGivenTypes", bookedExtrasForGivenTypes);

  const bookableServices = reservation.bookableServices?.filter((service) => {
    // console.log("service in BookableExtras", service);

    const { type, activityStatus, code, id } = service;
    const isInExtraTypes = extraTypes.indexOf(type) > -1;
    const excludeStatuses = ["requested", "booked", "notBooked"];
    const hasExcludedStatus =
      activityStatus && excludeStatuses.includes(activityStatus.status);

    const excludeService =
      code === "LATE_CHE" ||
      code === "LATE_DEP" ||
      code === "EARLY_ARR" ||
      code === "AD_BT_C40";

    return (
      isInExtraTypes &&
      !hasExcludedStatus &&
      !excludeService &&
      !service.hasAppliedBockedDays // remove services that have blocked days for the current reservation
    );
  });

  // Include bookableServices that have activityStatus set to "booked"
  const bookedBookableServices = reservation?.bookableServices?.filter(
    (service) => {
      const { type, activityStatus, code } = service;
      return (
        extraTypes.indexOf(type) > -1 &&
        activityStatus?.status === "booked" &&
        code !== "AD_SN_200" &&
        code !== "MOBILITY" &&
        code !== "AD_SS_C56" &&
        code !== "KLIM"
      );
    }
  );

  bookedExtrasForGivenTypes = [
    ...(bookedExtrasForGivenTypes || []),
    ...(bookedBookableServices || []),
  ];

  if (!bookedExtrasForGivenTypes || bookedExtrasForGivenTypes.length === 0) {
    bookedExtrasForGivenTypes = null;
  }

  // console.log("reservation.bookableServices: ", reservation.bookableServices);
  // Filter the reservation.bookableServices list to get the available extras that match the given extraTypes, exclude late dep and early arr
  const availableExtrasForGivenTypes = reservation?.bookableServices?.filter(
    (extra) => {
      return (
        extraTypes.indexOf(extra.type) > -1 &&
        extra.code !== "LATE_CHE" &&
        extra.code !== "LATE_DEP" &&
        extra.code !== "EARLY_ARR"
      );
    }
  );
  // console.log("availableExtrasForGivenTypes", availableExtrasForGivenTypes);

  // needed to hide the button bookmore if no more bookable experiences
  const availableExtrasWithoutStatus = availableExtrasForGivenTypes?.filter(
    (extra) => !extra.activityStatus
  );
  //display requested activities
  const requestedExtrasForGivenTypes = reservation?.bookableServices?.filter(
    (extra) => {
      return (
        extraTypes.indexOf(extra.type) > -1 &&
        extra.activityStatus &&
        extra.activityStatus?.status === "requested"
      );
    }
  );

  // Filter the reservation.bookableServices list to get the denied extras that match the given extraTypes
  const deniedExtrasForGivenTypes = reservation?.bookableServices?.filter(
    (extra) => {
      return (
        extraTypes.indexOf(extra.type) > -1 &&
        extra.activityStatus &&
        extra.activityStatus?.status === "notBooked"
      );
    }
  );

  // console.log("bookableServices " + extraTypes, bookableServices?.length);
  // console.log(
  //   "bookedExtrasForGivenTypes " + extraTypes,
  //   bookedExtrasForGivenTypes?.length
  // );
  // console.log(
  //   "requestedExtrasForGivenTypes " + extraTypes,
  //   requestedExtrasForGivenTypes?.length
  // );
  // console.log(
  //   "deniedExtrasForGivenTypes " + extraTypes,
  //   deniedExtrasForGivenTypes?.length
  // );

  // Check if any of the arrays have items
  const hasContent =
    (bookableServices && bookableServices.length > 0) ||
    (bookedExtrasForGivenTypes && bookedExtrasForGivenTypes.length > 0) ||
    (requestedExtrasForGivenTypes && requestedExtrasForGivenTypes.length > 0) ||
    (deniedExtrasForGivenTypes && deniedExtrasForGivenTypes.length > 0);

  // Only render if there's content to show
  return hasContent ? (
    <ContentBox>
      {/* <pre>ExtrasSection types: {extraTypes}</pre>
      <pre>ExtrasSection contentItem: {extraContentItem}</pre> */}

      {bookedExtrasForGivenTypes && bookedExtrasForGivenTypes.length > 0 && (
        <InfoBox>
          {bookedExtrasForGivenTypes.map((service, index) => {
            return (
              <ExtrasCardWrapper key={index}>
                {/* <pre>bookedExtrasForGivenTypes {service.name}</pre> */}
                <ExtrasCard
                  title={service.name}
                  showIcon={false}
                  service={service}
                  showSubTotal={false}
                  bookedExtrasForGivenTypes={bookedExtrasForGivenTypes}
                  showTotalPrice={true}
                  buttonProps={{
                    className: "primary",
                    text: dict("contents.bookedExtraDetails", lang),
                    to: {
                      pathname: `/extra`,
                      search: `reservation=${reservationId}&serviceId=${service.id}&type=booked&extraContentItem=${extraContentItem}`,
                    },
                    analyticsProductId: service.code,
                  }}
                />
              </ExtrasCardWrapper>
            );
          })}
        </InfoBox>
      )}

      {requestedExtrasForGivenTypes &&
        requestedExtrasForGivenTypes.length > 0 && (
          <InfoBox>
            {requestedExtrasForGivenTypes.map((service, index) => (
              <ExtrasCardWrapper key={index}>
                <ExtrasCard
                  title={service.name}
                  showIcon={false}
                  service={service}
                  showSubTotal={false}
                  buttonProps={{
                    className: "primary",
                    text: dict("contents.bookedExtraDetails", lang),
                    to: {
                      pathname: `/extra`,
                      search: `reservation=${reservationId}&serviceId=${service.id}&type=requested&extraContentItem=${extraContentItem}`,
                    },
                    analyticsProductId: service.code,
                  }}
                />
              </ExtrasCardWrapper>
            ))}
          </InfoBox>
        )}

      {deniedExtrasForGivenTypes && deniedExtrasForGivenTypes.length > 0 && (
        <InfoBox>
          {deniedExtrasForGivenTypes.map((service, index) => (
            <ExtrasCardWrapper key={index}>
              <ExtrasCard
                title={service.name}
                showIcon={false}
                service={service}
                showSubTotal={false}
                buttonProps={{
                  className: "primary",
                  text: dict("contents.bookedExtraDetails", lang),
                  to: {
                    pathname: `/extra`,
                    search: `reservation=${reservationId}&serviceId=${service.id}&type=notBooked&extraContentItem=${extraContentItem}`,
                  },
                  analyticsProductId: service.code,
                }}
              />
            </ExtrasCardWrapper>
          ))}
        </InfoBox>
      )}

      <BookableExtras
        extraContentItem={extraContentItem}
        extraTypes={extraTypes}
      />
    </ContentBox>
  ) : null;
}

export default ExtrasSection;
