import cabinIcon from "assets/images/cabin.svg";
import ButtonLink from "atoms/ButtonLink";
import CollapsibleReservation from "atoms/CollapsibleReservation";
import { InfoBoxWithMarginTop } from "components/Contents/styles/Contents.styled";
import Header from "components/Header";
import { contents } from "data/ContentOutline";
import { useAppContext } from "provider/AppProvider";
import { useReservationFromQueryParams } from "utils/helper";
import { hasExtraSectionContent } from "../utils/extraSectionHelpers";
import dict from "../utils/translations";

export default function ExtrasPage({ ...props }) {
  const { lang, user } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  if (!reservation) return null;

  const property = reservation.property;

  if (user && reservation && property) {
    // Filter content items that are extras sections and have content
    const extrasSections = contents.filter(
      (contentItem) =>
        (contentItem.id === "food" ||
          contentItem.id === "dog" ||
          contentItem.id === "kids" ||
          contentItem.id === "mobility" ||
          contentItem.id === "additional" ||
          contentItem.id === "activities") &&
        hasExtraSectionContent(reservation, contentItem.extraTypes)
    );

    return (
      <div>
        <Header />

        {extrasSections.length > 0 ? (
          <>
            <InfoBoxWithMarginTop>
              {dict("pages.extrasPageIntro", lang)}
            </InfoBoxWithMarginTop>

            {extrasSections.map((contentItem, index) => (
              <CollapsibleReservation
                key={index}
                reservation={reservation}
                contentItem={contentItem}
                // isExpanded={true}
                {...contentItem}
              />
            ))}
          </>
        ) : (
          <InfoBoxWithMarginTop>
            {dict("pages.noExtrasAvailable", lang) ||
              "No extras available for this reservation."}
          </InfoBoxWithMarginTop>
        )}

        <InfoBoxWithMarginTop>
          <ButtonLink
            caption={dict("pages.toReservation", lang)}
            to={"/reservation?reservation=" + reservationId}
            icon={cabinIcon}
          />
        </InfoBoxWithMarginTop>
      </div>
    );
  }
}
