import car from "assets/images/car2.svg";
import dog from "assets/images/dog.svg";
import klimLogo from "assets/images/klim-logo.svg";
import { useAppContext } from "provider/AppProvider";
import ButtonLink from "../../atoms/ButtonLink";
import ButtonLinkExtra from "../../atoms/ButtonLinkExra";
import dict from "../../utils/translations";
import { ContentBox, InfoBox } from "../Contents/styles/Contents.styled";

function BeforeTrip({ reservation, daysDiff }) {
  const { lang } = useAppContext();

  const petServiceBookable =
    reservation?.bookableServices?.find(
      (service) => service.code === "PETS" || service.code === "AD_DOG_CL7"
    ) ?? false;
  const petServiceBooked =
    reservation?.bookedServices?.find(
      (service) => service.code === "PETS" || service.code === "AD_DOG_CL7"
    ) ?? false;
  const petService = petServiceBookable || petServiceBooked;

  const shareNowCoopServiceBookable =
    reservation?.bookableServices?.find(
      (service) => service.code === "AD_SN_200"
    ) ?? false;
  const shareNowCoopServiceBooked =
    reservation?.bookedServices?.find(
      (service) => service.code === "AD_SN_200"
    ) ?? false;
  const shareNowCoopService =
    shareNowCoopServiceBookable || shareNowCoopServiceBooked;

  const shuttleServiceBookable =
    reservation?.bookableServices?.find(
      (service) => service.code === "AD_SS_C56"
    ) ?? false;
  const shuttleServiceBooked =
    reservation?.bookedServices?.find(
      (service) => service.code === "AD_SS_C56"
    ) ?? false;
  const shuttleService = shuttleServiceBookable || shuttleServiceBooked;

  const klimServiceBookable =
    reservation?.bookableServices?.find((service) => service.code === "KLIM") ??
    false;
  const klimServiceBooked =
    reservation?.bookedServices?.find((service) => service.code === "KLIM") ??
    false;
  const klimService = klimServiceBookable || klimServiceBooked;

  return (
    <ContentBox>
      {/* {Object.keys(contents).map((key, index) => {
                return (
                    <InfoBox >
                        <ButtonLink caption={contents[key].title} to={'/' + reservation.id + '/' + key} />
                    </InfoBox>
                );
            })} */}
      {daysDiff <= 1000 ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.connection", lang)}
            to={"/directions?reservation=" + reservation.id}
          />
        </InfoBox>
      ) : null}
      <InfoBox>
        <ButtonLink
          caption={dict("pages.packlist", lang)}
          to={"/packliste?reservation=" + reservation.id}
        />
      </InfoBox>
      {daysDiff <= 1000 ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.tips", lang)}
            to={"/tips?reservation=" + reservation.id}
          />
        </InfoBox>
      ) : null}

      {petService ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.pets", lang)}
            to={
              petServiceBooked
                ? "/extra?reservation=" +
                  reservation.id +
                  "&serviceId=" +
                  petService.id +
                  "&type=booked&extraContentItem=additional&extraTypes=Dog"
                : "/extra?reservation=" +
                  reservation.id +
                  "&serviceId=" +
                  petService.id +
                  "&type=bookable&extraContentItem=additional&extraTypes=Dog"
            }
            icon={dog}
          />
        </InfoBox>
      ) : null}

      {shuttleService ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.shuttle", lang)}
            to={
              shuttleServiceBooked
                ? "/extra?reservation=" +
                  reservation.id +
                  "&serviceId=" +
                  shuttleService.id +
                  "&type=booked&extraContentItem=mobility&extraTypes=Mobility"
                : "/extra?reservation=" +
                  reservation.id +
                  "&serviceId=" +
                  shuttleService.id +
                  "&type=bookable&extraContentItem=mobility&extraTypes=Mobility"
            }
            icon={car}
          />
        </InfoBox>
      ) : null}

      {shareNowCoopService ? (
        <InfoBox>
          {shareNowCoopServiceBooked ? (
            <ButtonLinkExtra
              caption={dict("contents.mobilityStart", lang)}
              href="https://www.share-now.com/de/de/promo/PSM-RAUS/"
              icon={car}
            />
          ) : (
            <ButtonLink
              caption={dict("pages.mobility", lang)}
              to={
                "/extra?reservation=" +
                reservation.id +
                "&serviceId=" +
                shareNowCoopService.id +
                "&type=bookable&extraContentItem=mobility&extraTypes=Mobility"
              }
              icon={car}
            />
          )}
        </InfoBox>
      ) : null}

      {klimService ? (
        <InfoBox>
          <ButtonLink
            caption={dict("pages.klim", lang)}
            to={
              klimServiceBooked
                ? "/extra?reservation=" +
                  reservation.id +
                  "&serviceId=" +
                  klimService.id +
                  "&type=booked&extraContentItem=additional&extraTypes=Additional"
                : "/extra?reservation=" +
                  reservation.id +
                  "&serviceId=" +
                  klimService.id +
                  "&type=bookable&extraContentItem=additional&extraTypes=Additional"
            }
            icon={klimLogo}
          />
        </InfoBox>
      ) : null}
    </ContentBox>
  );
}

export default BeforeTrip;
